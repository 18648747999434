.notification {
  background-color: $white;
  color: $black-light;
  border-radius: 2px;
  box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.23);
  line-height: 1.6;
  font-size: 14px;
  @media screen and (min-width: $breakpoint-lg) {
    width: 310px;
  }
  &__title {
    padding: 14px 16px;
    border-bottom: 1px solid $gray-light;
  }
  &__content {
    overflow-y: auto;
    @media screen and (min-width: $breakpoint-lg) {
      max-height: 430px;
    }
  }
  &__item {
    padding: 14px 16px;
    border-bottom: 1px solid $gray-light;
    &.active {
      background-color: $primary-lighter;
    }
  }

  &__input {
    width: 100%;
    background-color: $grey-lighter;
    padding: 12px;
  }
}

.modal--page .notification {
  @media screen and (min-width: $breakpoint-lg) {
    width: 100%;
  }

  &__content {
    @media screen and (min-width: $breakpoint-lg) {
      max-height: none;
    }
  }
}
