.header {
  position: relative;
  z-index: 2;
  color: $grey-subtext;
  height: $header-height;
  display: flex;
  align-items: center;
  background-color: $white;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  justify-content: space-between;
  padding: 16px;

  @media screen and (min-width: $breakpoint-md) {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.0444985);
  }

  .common__button {
    height: 42px;

    &--stake {
      font-size: 16px;
      font-weight: 600;
    }

    &--claim {
      padding: 12px 14px;
    }
  }

  &__logo {
    transition: transform 0.3s ease 0s;
    width: 110px;
    height: 40px;
    margin-right: 10px;
    svg {
      width: 100%;
      vertical-align: middle;
    }
    &:hover {
      transform: rotate(-5deg);
    }

    @media screen and (min-width: $breakpoint-md) {
      height: inherit;
      width: inherit;
    }
  }

  &__menu {
    margin: 0;
    padding: 0;

    > li {
      display: inline-block;

      &.header__menu__top {
        padding: 40px 25px 15px;
        border-bottom: 1px solid;

        .close {
          cursor: pointer;
          position: absolute;
          right: 12px;
          top: 10px;
          font-size: 30px;
          display: block;
          width: 35px;
          text-align: center;
        }
      }

      > a,
      .wrapper {
        padding: 10px;
        display: block;
        transition: 0.2s;
        position: relative;
        cursor: pointer;

        &.active.disabled {
          background-color: $primary;
        }

        @media screen and (min-width: $breakpoint-md) {
          padding: 14px;
        }
      }

      &:hover {
        color: $primary;
      }
    }

    &--right {
      width: 245px;
      position: fixed;
      background-color: $primary;
      right: 0;
      top: 0;
      bottom: 0;
      transform: translateX(100%);
      color: $white;
      &.active {
        transform: translateX(0%);
        transition: 0.3s;
      }

      li {
        display: block;
        &:hover {
          color: $white;
        }
        span {
          @media screen and (max-width: $breakpoint-md) {
            position: relative;
            .header__label {
              right: -25px;
              top: -2px;
            }
          }
        }
      }

      @media screen and (min-width: $breakpoint-md) {
        display: flex;
        align-items: center;
      }
    }

    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
      opacity: 0.5;
      display: none;

      &.active {
        display: block;
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      width: auto;
      position: static;
      background-color: transparent;
      transform: translateX(0%);
      li {
        display: inline-block;

        &.header__menu__top {
          display: none;
        }
      }
    }
  }
  &__menu-reward {
    display: flex !important;
    align-items: center;
    border: 1px solid $primary;
    border-radius: 4px;
    padding: 0;
    max-height: 42px;
    &__text {
      font-size: 14px;
      font-weight: 500;
      text-align: right;
      padding: 12px 14px;
      color: $primary;
    }

    &__value {
      font-size: 14px;
      font-weight: 500;
    }
    &.active {
      padding-top: 13px;
      padding-bottom: 13px;
      background-color: $primary-darker;
    }
  }

  &__menu-wallet {
    display: flex !important;
    align-items: center;
    color: #3a3a3a;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid $gray;
    background-color: $gray;
    font-size: 14px;
    max-height: 42px;
    span {
      padding: 12px 14px;
      font-style: normal;
      font-weight: 500;
    }

    &__value {
      border-radius: 3px;
      background-color: $gray-lighter;
      text-transform: uppercase;
      padding: 12px 14px;
      height: 42px;
      font-size: 14px;
    }
  }

  &__txs {
    position: absolute;
    right: 20px;
    .common__dropdown-title {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      text-align: center;
      padding-top: 9px;
      transition: 0.3s;
      display: block;
      &:hover {
        background-color: $primary-darker;
      }
    }
  }

  &__bars {
    cursor: pointer;
    position: relative;
    padding: 6px;
    background: #f5f5f5;
    border-radius: 4px;
    height: 36px;
    .header__label {
      right: -8px;
      top: 10px;
    }

    &__dropdown {
      background-color: $white;
      padding: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 1rem;
      z-index: 100;

      a {
        font-size: 16px;
        line-height: 22px;
        padding: 8px 0;
        display: flex;
        align-items: center;
        svg {
          margin-right: 4px;
        }

        &:hover {
          color: $black;
          svg {
            display: inline-block;
          }
          svg path {
            stroke: $black;
          }
        }
      }
    }
  }

  &__notification {
    padding: 11px;
    margin-left: 8px;
    position: relative;
  }

  .left,
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
