.report {
  &__item {
    font-size: 14px;
    font-weight: 500;
    color: #6a6a6a;
    background: white;
    padding: 30px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.05);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__tool {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    &.align-right {
      justify-content: flex-end;
    }

    &.align-left {
      justify-content: flex-start;
    }

    &-content {
      display: flex;
      align-items: center;
    }

    &-item {
      margin-right: 15px;
      cursor: pointer;

      &--border {
        padding: 4px 11px;
        border-radius: 5px;
        border: 2px solid transparent;
        position: relative;
        transition: all 0.3s;

        &.active {
          background-color: #f6f7fa;
          border-color: $primary;
        }

        &:before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 6px;
          margin-right: 7px;
          position: relative;
          bottom: 2px;
          border-radius: 3px;
        }

        &.red:before {
          background: #fd6694;
        }

        &.purple:before {
          background: #756df4;
        }

        &.emerald:before {
          background: #2acfe0;
        }
      }

      &--underscore {
        position: relative;

        &.active {
          color: $primary;

          &:after {
            width: 100%;
          }
        }

        &:after {
          content: "";
          width: 0;
          position: absolute;
          left: 0;
          bottom: -3px;
          height: 2px;
          background-color: $primary;
          transition: width 0.3s;
        }
      }

      &.active {
        cursor: initial;
        pointer-events: none;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    &__title {
      font-size: 18px;
    }

    &__item {
      font-size: 12px;
    }

    &__tool {
      display: block;

      &-content.right {
        margin-top: 15px;
      }
    }
  }
}
