.common {
  &__lh-5 {
    line-height: 1.5;
  }

  &__to-bottom {
    position: relative;
    top: 2px;
  }

  &__emerge {
    z-index: 9999 !important;
    pointer-events: none;
  }

  &__error {
    font-size: 12px;
    color: $red;
    word-break: break-word;
  }

  &__message-block {
    padding: 8px 20px;
    font-size: 13px;
    border-radius: 3px;
    display: inline-block;
    text-align: left;

    &--error {
      color: $red;
      background-color: $red-light;
    }

    &--info {
      color: $primary;
      background-color: #eaf5ff;
    }
  }

  &__message {
    font-size: 14px;
    color: $grey-light;
    word-break: break-word;
  }

  &__slide-up {
    animation: slide-up 0.3s forwards;
  }

  &__fade-in {
    animation: fade-in 0.3s forwards;
  }

  &__flex {
    display: flex;
    justify-content: space-between;

    &-center {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-horizon-center {
      display: flex;
      justify-content: center;
    }

    &-align-center {
      display: flex;
      align-items: center;
    }

    &-column {
      display: flex;
      flex-direction: column;
    }

    &-grow {
      flex-grow: 1;
    }
  }

  &__block {
    padding: 20px;
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0 12px 64px 0 rgba(162, 184, 195, 0.17);

    &-header {
      display: flex;
      justify-content: space-between;
    }

    &-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &-text {
      font-size: 14px;
    }
  }

  &__tabs {
    border-bottom: 1px solid #ddd;
    margin: 0;
    padding: 0 20px;
    font-size: 14px;
    display: inline-flex;

    &-item {
      font-weight: 500;
      list-style: none;
      padding: 10px 20px;
      position: relative;
      top: 1px;
      border-bottom: 1px solid transparent;
      cursor: pointer;

      &.active {
        color: $primary;
        border-color: $primary;
        border-width: 2px;
      }
    }

    &-panel {
      animation: slide-up 0.3s forwards;
    }

    @media screen and (max-width: $breakpoint-sm) {
      padding: 0;
    }
  }

  &__link {
    color: $primary;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;

    &--no-upercase {
      color: $primary;
      text-transform: none;
    }

    &--red {
      color: $red;
    }

    &:hover {
      text-decoration: underline;
    }

    &-text {
      color: $primary;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }

      &--black {
        color: $black-light;
      }
    }
  }

  &__button {
    font-size: 14px;
    text-align: center;
    padding: 10px 22px;
    display: inline-block;
    font-weight: 500;
    font-family: $font-base;
    border-radius: 4px;
    background-color: $primary;
    border: 1px solid $primary;
    color: $white;
    cursor: pointer;
    transition: all 0.3s;
    appearance: none;
    outline: none;
    vertical-align: middle;

    &:hover {
      border-color: $primary-darker;
      background-color: $primary-darker;
    }

    &--border-white {
      color: $primary;
      border: 1px solid $primary;
      border-radius: 3px;
      background-color: transparent;
      padding: 6px 17px;

      &:hover {
        background-color: $primary;
        border-color: $primary;
        color: $white;

        .common__triangle-right::before {
          border-left-color: $white;
        }
      }
    }

    &--green-button {
      background-color: $green;
      color: $white;
      border: 1px solid $green;
      border-radius: 3px;
      padding: 6px 17px;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        background-color: $green-dark;
      }

      &:disabled {
        background-color: $green-dark;
        border: 1px solid $green;
        cursor: not-allowed;
        opacity: 0.7;
      }
    }

    &--xsm {
      padding: 6px 12px;
    }

    &--sm {
      padding: 5px 20px;
    }

    &--lg {
      font-size: 16px;
      padding: 12px 35px;
    }

    &--md {
      font-size: 22px;
      padding: 13px 28px;
    }

    &--hollow {
      background-color: transparent;
      color: $primary;

      &:hover {
        border-color: $primary-lighter;
        color: white;
      }
    }

    &--success {
      background: linear-gradient($green, $green-dark);
      color: $white;

      &:hover {
        background: linear-gradient($green-dark, $green);
      }
    }

    &--primary {
      background: linear-gradient(to bottom, $primary, $primary-darker);
      color: $white;

      &:hover {
        background: linear-gradient($primary-darker, $primary);
      }
    }

    &--disabled {
      opacity: 0.2;
      pointer-events: none;
      cursor: initial;
    }

    &:active {
      transform: translateY(2px);
    }
  }

  &__triangle {
    &-right {
      &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 10px solid $primary;
      }
    }
  }

  &__timer {
    border-radius: 25px;
    background-color: $green-lighter;
    color: $primary;
    font-size: 10px;
    font-weight: 500;
    padding: 5px 12px;
    display: inline-flex;
    align-items: center;

    &::before {
      content: "";
      width: 14px;
      height: 16px;
      margin-right: 7px;
      background-image: url($image-path + "icons/timer.svg");
    }

    &--grey {
      color: $grey-subtext;
      background-color: #f5f5f5;

      &::before {
        content: none;
      }
    }
  }

  &__cancelled {
    font-size: 12px;
    padding: 7px 15px 7px 13px;
    display: inline-flex;
    align-items: center;
    color: $black-lighter;
    background: $gray-light;
    border-radius: 5px;
  }

  &__tag {
    &-item {
      font-size: 10px;
      font-weight: 500;
      display: inline-block;
      border-radius: 10px;
      border: 1px solid;
      padding: 2px 10px;
      background-color: $white;
      transition: all 0.3s;

      &:first-child {
        margin-left: 0;
      }

      &.clickable {
        cursor: pointer;

        &:active {
          transform: translateY(2px);
        }
      }

      &.blue {
        border-color: $blue-light;
        color: $blue-light;

        &.active {
          background-color: $blue-light;
          color: $white;
        }
      }

      &.violet {
        border-color: $violet;
        color: $violet;

        &.active {
          background-color: $violet;
          color: $white;
        }
      }

      &.sky {
        border-color: $sky;
        color: $sky;

        &.active {
          background-color: $sky;
          color: $white;
        }
      }

      &.green {
        border-color: $green;
        color: $green;

        &.active {
          background-color: $green;
          color: $white;
        }
      }

      &.orange {
        border-color: $orange;
        color: $orange;

        &.active {
          background-color: $orange;
          color: $white;
        }
      }

      &.grey {
        background-color: #f5f5f5;
        border-color: #f5f5f5;
        color: #868787;
      }
    }
  }

  &__input {
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    color: white;
    padding: 10px 25px 10px 15px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;

    &::placeholder {
      color: #edeaea;
    }

    @media screen and (min-width: $breakpoint-md) {
      width: 250px;
    }

    &-container {
      display: inline-block;
      position: relative;

      &::after {
        content: "";
        width: 24px;
        height: 24px;
        display: block;
        background-image: url($image-path + "icons/magnifier-grey.svg");
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
      }
    }
  }

  &__note {
    font-size: 12px;
    padding: 10px 14px;
    color: $primary;
    font-weight: 500;
    background-color: $primary-lighter;
    border-radius: 5px;
    margin-top: 10px;
    span {
      display: flex;
      align-items: center;
      padding-left: 42px;
      position: relative;
      min-height: 32px;
      line-height: 1.4;
      &::before {
        content: "";
        width: 32px;
        height: 32px;
        background-image: url($image-path + "/icons/note.svg");
        position: absolute;
        left: 0;
      }
    }
    &--box {
      color: white;
      padding: 10px 35px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 5px;
      font-size: 14px;
    }
  }

  &__option {
    display: inline-block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    margin-bottom: 0;

    &-input {
      position: absolute;
      visibility: hidden;
      pointer-events: none;

      &:checked ~ .common__option-radio {
        border-color: $primary;
        background-color: $primary;

        &::after {
          transform: translateY(-50%) scale(1);
        }
      }

      &:checked ~ .common__option-check {
        border-color: $primary;
        background-color: $primary;

        &::after {
          transform: translateY(-70%) rotate(-45deg) scale(1);
        }
      }
    }

    &-check {
      position: absolute;
      top: -1px;
      left: 0;
      height: 14px;
      width: 14px;
      border: solid 1px #d4e1ef;
      background-color: #ffffff;
      transition: all 0.2s;
      border-radius: 2px;

      &::after {
        content: "";
        display: block;
        width: 7px;
        height: 3px;
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-70%) rotate(-45deg) scale(0);
        transition: all 0.2s;
      }
    }

    &-radio {
      position: absolute;
      top: -1px;
      left: 0;
      height: 14px;
      width: 14px;
      border: solid 1px #d4e1ef;
      background-color: #ffffff;
      border-radius: 50%;
      transition: all 0.2s;

      &::after {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%) scale(0);
        transition: all 0.5s;
      }
    }
  }

  &__invisible {
    visibility: hidden;
  }

  &__disabled {
    cursor: initial;
    pointer-events: none;
    opacity: 0.7;
  }

  &__input-password {
    position: relative;

    input {
      width: 100%;
      background-color: $gray-lighter;
      padding: 14px 50px 14px 16px;
      font-size: 14px;
    }

    img {
      width: 22px;
      height: 15px;
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
  }

  &__dropdown {
    position: relative;
    cursor: default;

    &.active {
      .common__dropdown-wrapper {
        display: block;
      }
    }

    &-title {
      cursor: pointer;
    }

    &-wrapper {
      display: none;
      padding-top: 7px;
      position: absolute;
      right: 0;
      top: calc(100% + 8px);
      animation: slide-up 0.2s forwards;
    }

    &-content {
      position: relative;
      text-align: left;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      overflow: hidden;
    }
  }

  &__mobile {
    display: none;

    @media screen and (max-width: $breakpoint-lg) {
      display: block;
    }
  }

  &__tooltip {
    display: inline-block;
    position: relative;
    cursor: pointer;

    &:hover {
      .common__tooltip-content {
        opacity: 1;
        pointer-events: initial;
        transform: translate(-50%, 0);
      }
    }

    &-content {
      font-size: 12px;
      text-transform: initial;
      width: 120px;
      position: absolute;
      opacity: 0;
      text-align: center;
      padding: 7px 10px;
      border-radius: 3px;
      background-color: #333;
      color: white;
      top: -40px;
      pointer-events: none;
      z-index: 10;
      left: 50%;
      transform: translate(-50%, -10px);
      transition: all 0.3s;

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #333;
        left: 0;
        right: 0;
        bottom: -5px;
        margin: 0 auto;
        position: absolute;
      }
    }

    &--wide {
      margin-left: 5px;
      top: 1px;

      .common__tooltip-content {
        width: 350px;
        top: -65px;
        padding: 12px 15px;
        line-height: 1.4;
      }

      @media screen and (max-width: $breakpoint-lg) {
        display: none;
      }
    }
  }

  &__animated-letter {
    display: inline-block;
    position: relative;
    transform: translateY(-20px);
    animation: jumping-letter 1.4s forwards;
    animation-delay: 0.01s;

    &:nth-child(2) {
      animation-delay: 0.02s;
    }

    &:nth-child(3) {
      animation-delay: 0.03s;
    }

    &:nth-child(4) {
      animation-delay: 0.04s;
    }

    &:nth-child(5) {
      animation-delay: 0.05s;
    }

    &:nth-child(6) {
      animation-delay: 0.06s;
    }
  }

  &__badge {
    position: absolute;
    right: 0;
    top: 3px;
    font-size: 8px;
    color: $white;
    border-radius: 7.5px;
    padding: 4px 3px;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 16px;
  }
}

.body-wrapper {
  min-height: calc(100vh - #{$header-height + $footer-mobile-height});

  @media screen and (min-width: $breakpoint-md) {
    min-height: calc(100vh - #{$header-height + $footer-height});
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(3000px 900px at 50% 0%, rgba(0, 255, 180, 0.2) 0%, rgba(0, 255, 180, 0) 100%);
    z-index: -10;
  }

  &--page::before {
    content: none;
  }

  .container {
    padding: 0 16px;
  }
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font {
  &-xs {
    font-size: 10px;
  }

  &-sm {
    font-size: 12px;
  }

  &-md {
    font-size: 14px;
  }

  &-lg {
    font-size: 18px;
  }

  &-bold {
    color: #141927;
  }

  &-light {
    color: #565656;
  }
}

.text-underline {
  text-decoration: underline;
}

.mb-1-px {
  margin-bottom: 1px;
}

.h-fit-content {
  height: fit-content;
}

.pointer {
  cursor: pointer;
}

.nice-scroll {
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #8f8f8f;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #8f8f8f;
  }
}

.body-scroll {
  // /* width */
  // &::-webkit-scrollbar {
  //   width: $scrollBarWidth;
  // }

  // /* Track */
  // &::-webkit-scrollbar-track {
  //   background: lighten($gray-bold, 40%);
  // }

  // /* Handle */
  // &::-webkit-scrollbar-thumb {
  //   background-color: $gray-bold;
  // }

  // /* Handle on hover */
  // &::-webkit-scrollbar-thumb:hover {
  //   background-color: darken($gray-bold, 10%);
  // }
  overflow: overlay;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: $scrollBarWidth;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background-color: $grey-light;
    border: 4px solid transparent;
    background-clip: content-box;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: $grey;
  }
}

.hide {
  display: none !important;
}

.view-on {
  justify-content: left;
  margin-top: 20px;

  a {
    width: 18px;
    height: 18px;
    margin: 2px 0 0 12px;
  }
}

.align-items {
  &-flex-end {
    align-items: flex-end;
  }
}

input {
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 16px;
  }
}
