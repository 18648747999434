@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes floating-up-and-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes floating-left-and-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes jumping-letter {
  0% {
    transform: translateY(-8px);
  }
  45% {
    transform: translateY(5px);
  }
  65% {
    transform: translateY(-4px);
  }
  85% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
