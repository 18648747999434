.history {
  height: 100%;

  &__table {
    padding: 0 14px;
    font-size: 14px;

    .row {
      line-height: 30px;
    }
  }

  table {
    width: 100%;

    tr:not(:first-child) {
      td {
        padding-top: 10px;
      }
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 500;
    padding: 8px 14px;
    border-bottom: 1px solid $black-lightest;
    margin-bottom: 15px;
  }

  @media screen and (max-width: $breakpoint-md) {
    &__table {
      &-epoch {
        margin-bottom: -12px;
      }

      .row {
        line-height: 35px;
      }
    }

    &__subtitle {
      margin-bottom: 10px;
    }
  }
}
