.account {
  padding: 15px;
  position: relative;
  transition: all 0.3s;
  color: $black-light;
  background-color: $white;

  @media screen and (min-width: $breakpoint-lg) {
    padding: 20px;
    width: 330px;
    max-width: 100%;
  }

  &.imported {
    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  &--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: initial !important;
    z-index: 2;
    opacity: 0.9;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.48);
    border: solid 1px #dcdbdc;
    border-radius: 0;
    @media screen and (min-width: $breakpoint-lg) {
      display: none;
    }
  }

  &--closed {
    opacity: 0 !important;
    transform: translateY(100%);
  }

  &__close {
    position: absolute;
    top: 2px;
    right: 15px;
    font-size: 22px;

    @media screen and (min-width: $breakpoint-lg) {
      display: none;
    }
  }

  &__title {
    margin-bottom: 8px;
  }

  &__main-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
    @media screen and (min-width: $breakpoint-lg) {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    width: 48%;
    padding: 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: solid 1px $black-lightest;
    cursor: pointer;
    transition: all 0.3s;

    &-name {
      color: $black;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 7px;
      background: 0 0 no-repeat;
      background-size: cover;

      &.metamask {
        background-image: url($image-path + "wallets/metamask.svg");
      }

      &.ledger {
        background-image: url($image-path + "wallets/ledger.svg");
      }

      &.keystore {
        width: 22px;
        background-image: url($image-path + "wallets/keystore.svg");
      }

      &.trezor {
        width: 14px;
        background-image: url($image-path + "wallets/trezor.svg");
      }

      &.coinbase-wallet {
        background-image: url($image-path + "wallets/coinbase-wallet.svg");
      }

      &.wallet-connect {
        width: 22px;
        height: 13px;
        background-image: url($image-path + "wallets/wallet-connect.svg");
      }
    }

    &:hover {
      box-shadow: 0 3px 7px 0 rgba(143, 165, 188, 0.38);

      .account__item-name {
        color: $primary;
      }

      .account__item-icon {
        &.metamask {
          background-position: -31px 0;
        }

        &.ledger {
          background-position: -33px 0;
        }

        &.keystore {
          background-position: -33px 0;
        }

        &.trezor {
          background-position: -29px 0;
        }

        &.coinbase-wallet {
          background-position: -38px 0;
        }

        &.wallet-connect {
          background-position: -43px 0;
        }
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;

    &-small {
      font-size: 12px;
    }
  }

  &__close-icon {
    position: relative;
    top: 1px;
    cursor: pointer;
  }

  &__icon {
    height: 11px;
    cursor: pointer;
  }

  &__block {
    border-bottom: 1px dashed $black-lightest;
    padding: 15px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &__address {
    font-size: 14px;
    line-height: 18px;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 400;

    &::before {
      content: "";
      width: 7px;
      height: 7px;
      display: inline-block;
      border-radius: 50%;
      background-color: $primary;
      position: relative;
      top: -1px;
      margin-right: 5px;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  &__input {
    font-size: 12px;
    width: 100%;
    border-radius: 5px;
    background-color: $grey-lighter;
    padding: 11px 15px;
    border: 1px solid transparent;

    &.error {
      border-color: $red;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    .common__tooltip--wide {
      display: initial;
    }
  }
}

.modal {
  &--page {
    .account {
      display: block;
      position: initial;

      &__close {
        display: none;
      }
    }
  }
  &__content {
    .account {
      &__main-title,
      &__close {
        display: none;
      }
    }
  }
}
