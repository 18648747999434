.device-modal {
  width: 430px;
  max-width: 100%;

  &__content {
    padding: 35px 30px 25px;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    color: $black-light;
    margin-bottom: 20px;
  }

  &__sub-title {
    font-size: 14px;
    font-weight: bold;
    color: $grey;
    margin-bottom: 10px;
  }

  &__dropdown {
    &-trigger {
      font-size: 14px;
      padding: 13px 15px;
      border-radius: 3px;
      margin-bottom: 25px;
      background-color: $gray-lighter;
      cursor: pointer;
      position: relative;
    }

    &-content {
      position: absolute;
      width: 100%;
      background: $gray-lighter;
      top: 100%;
      margin: 0 0 30px !important;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 14px;
      z-index: 1;
    }

    &-item {
      padding: 10px 80px 10px 15px;
      cursor: pointer;
      transition: all .3s;

      div:last-child {
        color: $grey-light;
      }

      &:hover {
        background: $gray-light;
      }

      &:last-child {
        padding-right: 15px;
        margin-bottom: 15px;
        cursor: initial;
        position: relative;
      }

      &.selected {
        position: relative;
        cursor: initial;
        pointer-events: none;

        &::after {
          content: '';
          width: 18px;
          height: 14px;
          background-image: url($image-path + 'icons/checkmark.svg');
          position: absolute;
          top: 50%;
          margin-top: -7px;
          right: 20px;
        }
      }

      input {
        width: 100%;
        padding: 10px 10px;
        border-radius: 3px;
        font-size: 14px;
      }
    }

    &-button {
      background: $gray-bold url($image-path + 'icons/angle-right.svg') center no-repeat;
      position: absolute;
      top: 13px;
      right: 20px;
      border-radius: 5px;
      padding: 15px;
      cursor: pointer;
    }
  }

  &__select {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    &-container {
      min-height: 130px;
    }

    &-address {
      font-size: 14px;
    }

    &-data {
      display: flex;
      align-items: center;
    }

    &-value {
      font-size: 15px;
      margin-right: 15px;
    }

    &-button {
      padding: 7px 14px;
    }
  }

  &__panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 35px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: $grey-lighter;

    &-button {
      width: 16px;
      height: 16px;
      background-image: url($image-path + 'icons/arrow-right.svg');
      cursor: pointer;

      &.back {
        transform: rotate(180deg);
      }
    }
  }
}
