@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

@import "settings/variables";
@import "./node_modules/bootstrap/scss/grid";
@import "./node_modules/bootstrap/scss/utilities";
@import "settings/animations";
@import "settings/elements";

@import "./node_modules/swiper/swiper";
@import "./node_modules/swiper/components/pagination/pagination";

@import "components/commons/common";
@import "components/commons/icons";
@import "components/commons/epoch-progress";
@import "components/commons/stake-info";
@import "components/commons/slide-down";
@import "components/commons/gas-option";

@import "components/modals/modal";
@import "components/modals/modal-device";

@import "components/layouts/header";
@import "components/layouts/footer";
@import "components/layouts/error-page";

@import "components/accounts/account";
@import "components/accounts/account-balance";

@import "components/learn";
@import "components/history";
@import "components/proposal";
@import "components/reward";
@import "components/staking";
@import "components/notification";
@import "components/delegation";
@import "components/faq";
@import "components/about";
@import "components/migration";
@import "components/report";

/* work-sans-300 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/work-sans/work-sans-v13-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/work-sans/work-sans-v13-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans/work-sans-v13-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/work-sans/work-sans-v13-latin-300.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-regular - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/work-sans/work-sans-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/work-sans/work-sans-v13-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans/work-sans-v13-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/work-sans/work-sans-v13-latin-regular.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-500 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/work-sans/work-sans-v13-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/work-sans/work-sans-v13-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans/work-sans-v13-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/work-sans/work-sans-v13-latin-500.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/work-sans/work-sans-v13-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/work-sans/work-sans-v13-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans/work-sans-v13-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-600.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/work-sans/work-sans-v13-latin-600.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-700 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/work-sans/work-sans-v13-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/work-sans/work-sans-v13-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans/work-sans-v13-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/work-sans/work-sans-v13-latin-700.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-800 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/work-sans/work-sans-v13-latin-800.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/work-sans/work-sans-v13-latin-800.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans/work-sans-v13-latin-800.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-800.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-800.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/work-sans/work-sans-v13-latin-800.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-300italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/work-sans/work-sans-v13-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/work-sans/work-sans-v13-latin-300italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans/work-sans-v13-latin-300italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-300italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-300italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/work-sans/work-sans-v13-latin-300italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/work-sans/work-sans-v13-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/work-sans/work-sans-v13-latin-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans/work-sans-v13-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/work-sans/work-sans-v13-latin-italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-500italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/work-sans/work-sans-v13-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/work-sans/work-sans-v13-latin-500italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans/work-sans-v13-latin-500italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-500italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-500italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/work-sans/work-sans-v13-latin-500italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-600italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/work-sans/work-sans-v13-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/work-sans/work-sans-v13-latin-600italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans/work-sans-v13-latin-600italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-600italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-600italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/work-sans/work-sans-v13-latin-600italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-700italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/work-sans/work-sans-v13-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/work-sans/work-sans-v13-latin-700italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans/work-sans-v13-latin-700italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-700italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-700italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/work-sans/work-sans-v13-latin-700italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
/* work-sans-800italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/work-sans/work-sans-v13-latin-800italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/work-sans/work-sans-v13-latin-800italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/work-sans/work-sans-v13-latin-800italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-800italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/work-sans/work-sans-v13-latin-800italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/work-sans/work-sans-v13-latin-800italic.svg#WorkSans") format("svg"); /* Legacy iOS */
}
