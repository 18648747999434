.about {
  padding-top: 70px;
  background-image: url($image-path + "backgrounds/about-background.png");
  background-repeat: no-repeat;
  background-size: contain;
  @media screen and (min-width: $breakpoint-lg) {
    padding-top: 100px;
  }

  &__wrapper {
    @media screen and (min-width: $breakpoint-lg) {
      padding-bottom: 45px;
    }
  }

  .container {
    padding: 0 16px;
  }

  &__title {
    font-size: 28px;
    line-height: 32px;
    color: #3a3a3a;
    font-weight: 300;
    text-align: center;
    margin-bottom: 24px;
    .text-success {
      font-weight: 500;
    }
    @media screen and (min-width: $breakpoint-md) {
      width: 620px;
      font-size: 48px;
      line-height: 60px;
      text-align: left;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #3a3a3a;
    text-align: center;
    p {
      margin: 20px 0;
    }
    @media screen and (min-width: $breakpoint-md) {
      font-size: 20px;
      line-height: 28px;
      width: 600px;
      text-align: left;
    }
  }

  &__section-title {
    font-size: 28px;
    line-height: 32px;
    font-weight: 500;
    margin: 20px 0;

    @media screen and (min-width: $breakpoint-md) {
      font-size: 36px;
      line-height: 42px;
      margin: 48px 0;
    }
  }

  &__text {
    margin-top: 10px;
    font-size: 16px;
    line-height: 24px;
    color: #3a3a3a;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: $breakpoint-sm) {
      margin-top: 20px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__top {
    position: relative;
    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: 100px;
    }

    &__buttons {
      display: flex;

      .common__button {
        border-radius: 8px;
        padding: 13px 20px;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        @media screen and (min-width: $breakpoint-md) {
          width: 216px;
          height: 52px;
        }

        &--hollow {
          border-color: #3a3a3a;
          color: #3a3a3a;
          &:hover {
            border-color: #999;
            background: $white;
          }
        }
      }
    }

    &__image {
      position: absolute;
      right: -100px;
      top: 0px;
    }
  }

  &__diagram {
    padding: 40px 0;
    margin: 20px 0;
    text-align: center;
  }

  &__partners {
    .third-party {
      display: flex;
      justify-content: center;
      align-items: center;

      &__icon {
        margin: 15px;
        padding: 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        transition: all 0.3s;

        &:hover {
          box-shadow: 0px 0px 15px #d4d4d4;
        }

        @media screen and (min-width: $breakpoint-md) {
          width: 300px;
          height: 100px;
        }
      }
    }
    .about-text {
      width: 725px;
      max-width: 75%;
    }
    .swiper-container {
      margin: 30px 0;
      padding-bottom: 55px;
    }
    .swiper-slide {
      width: 85%;
      max-height: 450px;
      overflow: hidden;
      max-width: 1100px;
    }
    .swiper-pagination {
      &-bullet {
        width: 36px;
        height: 4px;
        opacity: 0.2;
        border-radius: 9999px;
        border: solid 1px #707070;
        background-color: #707070;
        &-active {
          opacity: 1;
        }
      }
    }
    &-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(31, 31, 31, 0.25);
      z-index: 1;
    }
  }

  &__defi {
    padding: 50px 0;
    background-image: url($image-path + "backgrounds/about-social-bgr.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &-img {
      position: relative;
      a {
        position: absolute;
        width: 10%;
        height: 23%;
        opacity: 0;
      }
      .github {
        left: 5.8%;
        top: 46%;
      }
      .discord {
        left: 23%;
        top: 28%;
      }
      .reddit {
        left: 50.2%;
        top: 27%;
      }
      .twitter {
        left: 39.2%;
        top: 49%;
      }
      .medium {
        left: 69.8%;
        top: 35%;
      }
      .youtube {
        left: 86.2%;
        top: 51%;
      }
      @media screen and (min-width: $breakpoint-md) {
        .github {
          left: 10.2%;
        }
        .discord {
          left: 25.2%;
        }
        .reddit {
          left: 50.2%;
        }
        .twitter {
          left: 39.2%;
        }
        .medium {
          left: 66.8%;
        }
        .youtube {
          left: 81.2%;
        }
      }
    }
  }

  &__knc {
    margin: 100px 0;
  }

  &__faq {
    background-color: $gray-lighter;
    &__container {
      padding: 48px 16px 0 16px;
      border-radius: 8px;
    }
    .left {
      img {
        display: block;
        margin: 0 auto;
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      max-width: 936px;
      margin: 0 auto;
      margin-bottom: 160px;
      border-radius: 8px;
      &__container {
        padding: 40px 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .common__button {
    &--learn-more {
      font-size: 16px;
      padding: 16px;
      margin: 48px 0;
      width: 100%;
      border-radius: 8px;

      @media screen and (min-width: $breakpoint-md) {
        width: 216px;
        height: 52px;
      }
    }
  }
}
