.reward {
  &__box {
    height: 100%;
    background-color: $grey-lighter;
    padding: 30px;
    border-radius: 3px;

    &-header {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $black-lightest;
      display: flex;
      font-weight: 500;
      justify-content: space-between;
      align-items: baseline;
    }

    &-link {
      font-size: 12px;
      text-transform: uppercase;
      color: $primary;
      cursor: pointer;
    }

    &-body {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__button-container {
    text-align: center;
  }

  &__button {
    text-align: center;
    min-width: 128px;

    &--claimed {
      pointer-events: none;
      color: $black-lighter;
      border-color: $gray-lightest;
      background-color: $gray-lightest;
    }

    @media screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }

  &__total {
    display: inline-flex;
    align-items: center;
    background-color: $grey-lighter;
    padding: 6px 10px;
    border-radius: 3px;
    margin-right: 15px;

    &-image {
      width: 22px;
      height: 22px;
      background-image: url($image-path + "tokens/eth.svg");
      background-size: contain;
    }

    &--blue {
      color: $blue;
      background-color: $primary-lighter;

      .reward__total-image {
        background-image: url($image-path + "tokens/eth-blue.svg");
      }
    }
  }
}
