.epoch-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: $black-light;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 30px;

  &__ongoing {
  }

  &__timer {
    margin: 5px 20px;
    color: $primary;
    background-color: #d8faf0;
    font-size: 14px;
    &::before {
      background-image: url($image-path + "/icons/timer-green.svg");
    }
  }

  &__info {
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
  }
}
