.proposal {
  &__block {
    background-color: white;
    border-radius: 5px;
    padding: 20px 16px;
    box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.05);
    @media screen and (min-width: $breakpoint-md) {
      padding: 20px 25px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;
      align-items: flex-end;
    }
    &-text {
      color: $black-light;
      font-size: 14px;
      margin-top: 7px;
    }
    .common__input {
      background-color: $white;
      background: $white;
      color: $black-light;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.04);
    }
    .common__input::placeholder {
      font-size: 12px;
      font-weight: 400;
      color: #b6b6b6;
    }
  }

  &__filter {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: $white;
    font-weight: 500;
    margin-bottom: 12px;
    @media screen and (min-width: $breakpoint-xs) {
      justify-content: flex-start;
    }
    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: 0px;
    }

    &-item {
      background-color: $white;
      padding: 8px 6px;
      margin-right: 4px;
      border-radius: 3px;
      cursor: pointer;
      transition: 0.3s;
      color: $black-light;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.04);
      &.active,
      &:hover {
        background-color: $primary;
        color: $white;
      }
      &:last-child {
        margin-right: 0px;
      }
      @media screen and (min-width: $breakpoint-md) {
        margin-right: 8px;
        padding: 12px 11px;
      }
    }
  }
  &__search-input {
    width: 100%;
    @media screen and (min-width: $breakpoint-md) {
      display: inline-block;
      text-align: right;
    }
  }
  &__suggest {
    font-size: 10px;
    color: $grey-light;
    text-align: right;
    margin: 10px;
  }

  &__description {
    font-size: 14px;
    color: $black;
    margin-bottom: 30px;
  }

  &__body {
    margin-bottom: 50px;
  }

  &__item {
    display: flex;
    &--ended {
      .proposal__item-data,
      .common__link,
      .proposal__item-options,
      .proposal__item-actions {
        display: none;
      }

      .proposal__item-header {
        width: calc(100% - 300px);
      }

      .proposal__item-status {
        min-width: 416px;
        @media screen and (max-width: $breakpoint-md) {
          min-width: 316px;
        }
        justify-content: flex-end;

        .common__tag {
          margin-right: 10px;

          &-item {
            margin-right: 8px;
          }
        }
      }

      .proposal__item-title {
        margin-bottom: 0;
      }

      .proposal__item-info {
        width: 100%;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .text-right {
        display: none;
      }
    }

    &-info {
      width: 30%;
      padding-right: 15px;
    }

    &-options {
      width: 54%;
      border-right: 1px dashed $gray;
      border-left: 1px dashed $gray;
      padding: 0 15px;
    }

    &-actions {
      width: 16%;
      padding-left: 15px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .common__button {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &-status {
      display: block;
      margin-bottom: -8px;
      & > div {
        margin-right: 8px;
        margin-bottom: 8px;
      }
      .common__tag {
        margin-right: 7px;
        display: inline-block;

        &-item {
          margin-right: 5px;
          padding: 4px 8px;

          &:first-child {
            margin-right: 0;
          }
        }
      }
      @media screen and (min-width: $breakpoint-md) {
        text-align: right;
      }
    }

    &-title {
      display: inline-block;
      font-weight: 500;
      color: $black;
      word-break: break-word;
      margin-bottom: 10px;

      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }

    &-win {
      font-size: 14px;
      color: $grey;
      margin-top: 8px;
      font-weight: 500;
    }

    &-data {
      display: flex;
      margin: 15px 0 10px;
      padding: 15px 13px;
      font-size: 14px;
      line-height: 1.8;
      color: $black;
      text-transform: uppercase;
      border-radius: 5px;
      background-color: $grey-lighter;

      > div {
        width: 30%;
        margin-right: 5%;
        font-size: 11px;
        color: #565656;
        line-height: 1.1;
        font-weight: 500;

        &:last-child {
          margin-right: 0;
        }
      }

      b {
        color: black;
        font-size: 14px;
      }
    }

    &-power {
      font-size: 12px;
      position: relative;
      bottom: 1px;
    }

    &-social {
      display: flex;
      align-items: center;
    }

    &-learn {
      font-size: 14px;
      font-weight: 500;
      color: $black;
      margin-right: 15px;
    }

    &-social-icon {
      margin-top: -1px;
    }

    &-block {
      background: #f5f5f5;
      border-radius: 5px;
      position: relative;
      margin-bottom: 7px;
      letter-spacing: 0.2px;
      font-size: 13px;
      font-weight: 500;
      overflow: hidden;
      &.disabled {
        pointer-events: none;
        cursor: initial;
      }
    }

    &-option {
      width: 48%;
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      position: relative;
      margin: 0 4% 15px 0;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &--checked {
        .proposal__item-block {
          background: #e3ebff;
        }

        .proposal__item-progress {
          background-color: $primary-light;
        }
      }

      &--winning {
        .proposal__item-block {
          background: #ebf7e5;
        }

        .proposal__item-progress {
          background-color: #d4e8c9;
        }
      }

      label {
        width: 100%;
        height: 100%;
        padding: 15px 10px 15px 35px;
        z-index: 3;

        .common__option-check,
        .common__option-radio {
          top: 14px;
          left: 14px;
        }

        .common__option-text {
          top: 1px;
          position: relative;
          font-weight: 400;
        }
      }
    }

    &-progress {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #e9e9e9;
      border-radius: 3px;
    }

    &-bot {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-percent {
      color: white;
      border-radius: 3px;
      background-color: $grey;
      padding: 2px 6px;
      margin-right: 7px;
    }

    &-name {
      min-height: 18px;
      display: flex;
      align-items: center;
    }

    &-view {
      cursor: pointer;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    &__item {
      display: block;

      &--ended {
        .proposal__item-info {
          display: block;
        }

        .proposal__item-header {
          width: 100%;
        }

        .proposal__item-status {
          width: 100%;
          justify-content: flex-start;
          flex-direction: initial;
          margin-top: 15px;
        }
      }

      &-info,
      &-options,
      &-actions {
        width: 100%;
        padding: 0;
      }

      &-options {
        border: none;
      }

      &-actions {
        flex-direction: initial;
        justify-content: flex-start;

        .common__button {
          width: initial;
          margin-right: 15px;
        }
      }

      &-data {
        margin-bottom: 20px;
      }

      .text-right {
        display: none;
      }
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    &__item {
      &-option {
        display: block;
        width: 100%;
      }
    }
  }
}

.proposal-details {
  margin-top: 30px;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__header {
    margin-bottom: 20px;
  }

  &__back {
    padding: 6px 0;
    margin-bottom: 15px;
    cursor: pointer;
    color: $primary;
    opacity: 0.7;
    display: inline-flex;
    align-items: center;
    svg path {
      fill: $primary;
    }
    img {
      margin-right: 10px;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__title {
    color: $black-light;
    margin-bottom: 12px;
    font-size: 20px;
  }

  &__desc {
    font-size: 14px;
    line-height: 1.5;
    word-break: break-word;
    a {
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        color: $primary;
      }
    }
  }

  &__left {
    width: 35%;

    .proposal__item-data {
      margin-top: 0;
    }
  }

  &__right {
    width: 62%;
  }

  .proposal__item-options {
    width: 100%;
    border: none;
    padding: 0;
  }

  .proposal__item-data {
    margin-bottom: 15px;
  }

  .proposal__address {
    &-recap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $gray-light;
      padding: 10px 0 5px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px dashed $gray-light;
      padding: 8px 0;
      font-weight: 500;
      font-size: 13px;

      &:first-child {
        padding: 5px 0 5px 13px;
        font-size: 11px;
      }
    }

    &-name {
      width: 70%;
      text-align: left;
      &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 7px;
        background-color: #b8babe;
        position: relative;
        bottom: 1px;
      }
    }
  }

  .proposal__item-actions {
    width: 100%;
    margin-top: 20px;
    flex-direction: initial;
    justify-content: flex-end;

    .common__button {
      width: initial;
      margin: 0 0 0 15px;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    &__container {
      display: block;
    }

    &__header {
      background-color: white;
      margin-bottom: 16px;
      padding: 20px;
      border-radius: 5px;
    }

    &__back {
      margin-bottom: 15px;
    }

    &__title {
      color: $black;
    }
    &__left {
      margin-bottom: 16px;
    }
    &__left,
    &__right {
      width: 100%;
    }
  }
}
