.faq {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__left {
    width: 20%;
    position: sticky;
    top: 3px;
    padding-top: 30px;
  }

  &__right {
    width: 80%;
    background-color: white;
    border-radius: 5px;
    padding: 30px 25px 30px 40px;
  }

  &__glossary {
    display: block;
    font-size: 20px;
    font-weight: 500;
    color: $black;
    margin-bottom: 40px;
    cursor: pointer;

    &.active {
      color: $primary;
      pointer-events: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__type {
    margin-bottom: 10px;

    &:first-child .faq__title {
      margin-top: 0;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    color: $primary;
    margin: 30px 0 5px;
  }

  &__bold {
    font-weight: 500;
    color: $primary;
  }

  &__item {
    z-index: 1;
    padding: 15px 0;
    border-bottom: 1px solid $gray-light;

    &:last-child {
      border-bottom: none;
    }

    &-title {
      color: $black;
      padding-right: 35px;
    }

    &-text {
      font-size: 14px;
      color: #727272;
      margin: 20px 0 10px;
      line-height: 1.5;
    }

    &-link {
      color: $primary;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &-img {
      display: block;
      margin: 10px auto 5px;
    }
  }

  .slide-down__fade-in {
    z-index: 1;
  }

  .slide-down__arrow {
    border-top-color: $grey-light;
  }

  @media screen and (max-width: $breakpoint-lg) {
    display: block;

    &__left {
      display: none;
    }

    &__right {
      width: 100%;
      padding: 20px 16px 20px 25px;
    }
  }
}
