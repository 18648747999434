.account-balance {
  margin: 0 auto;

  &__input-container {
    display: flex;
    border-radius: 5px;
    height: 48px;
    margin-bottom: 6px;
    border: 1px solid transparent;
    transition: border-color;

    &.error {
      border-color: $red;
    }
  }

  &__label {
    width: 100px;
    color: $grey;
    background-color: #ecebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &__input {
    width: calc(100% - 100px);
    font-size: 18px;
    background-color: $gray-lighter;
    padding: 0 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__balance {
    font-weight: 500;

    &-container {
      font-size: 13px;
    }
  }

  &__percentage-container {
    display: flex;
  }

  &__percentage {
    width: 45px;
    padding: 5px 0;
    margin-left: 5px;
    font-size: 12px;
    text-align: center;
    border-radius: 3px;
    border: solid 1px $gray-dark;
    background-color: $gray-lighter;
    cursor: pointer;
    transition: background-color 0.3s;

    &:first-child {
      margin-left: 0;
    }

    &.active,
    &:hover {
      background-color: $gray-dark;
    }

    &.disabled {
      pointer-events: none;
      cursor: initial;
    }

    &:active {
      transform: translateY(1px);
    }
  }
}