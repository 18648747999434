.stake-info {
  color: $black-light;
  margin-bottom: 12px;
  padding-top: 100px;
  @media screen and (min-width: $breakpoint-lg) {
    margin-bottom: 36px;
  }

  &__title {
    font-size: 36px;
    line-height: 60px;
    font-weight: 300;
    text-align: center;
    .text-success {
      font-weight: 600;
    }
    @media screen and (min-width: $breakpoint-md) {
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 48px;
    }
  }

  &__item {
    margin-top: 16px;

    &-wrapper {
      border-radius: 8px;
      padding: 12px;
      background-color: $white;
      border: 1px solid $primary;
      border-bottom: 12px solid $primary;
      height: 100%;
    }

    &:nth-child(1) {
      .stake-info__item-wrapper {
        border: 1px solid #1d7a5f;
        box-shadow: 0px 4px 0 0px #1d7a5f;
      }
      .stake-info__item-value {
        color: #1d7a5f;
      }
    }
    &:nth-child(2) {
      .stake-info__item-wrapper {
        border: 1px solid $primary;
        box-shadow: 0px 4px 0 0px $primary;
      }
      .stake-info__item-value {
        color: $green;
      }
    }
    &:nth-child(3) {
      .stake-info__item-wrapper {
        border: 1px solid #a7b6bd;
        box-shadow: 0px 4px 0 0px #a7b6bd;
      }
      .stake-info__item-value {
        color: #a7b6bd;
      }
    }

    &-title {
      font-size: 10px;
      color: $grey-subtext;
      text-transform: uppercase;
    }

    &-subvalue {
      font-size: 12px;
      color: $grey-subtext;
    }

    &-value {
      font-size: 18px;
      font-weight: 500;
      margin-top: 5px;
      display: flex;
      align-items: baseline;

      &.no-flex-mobile {
        display: initial;
      }
    }

    &-subvalue {
      font-size: 12px;
      margin-left: auto;
      font-weight: 400;
    }

    &-unit {
      font-size: 14px;
      margin-left: 4px;
    }

    @media screen and (min-width: $breakpoint-lg) {
      margin-top: 20px;

      &-wrapper {
        padding: 20px;
      }
      &-title {
        font-size: 14px;
      }

      &-value {
        font-size: 36px;
        margin-top: 11px;
        flex-direction: column;
        display: flex;

        &.no-flex-mobile {
          display: flex;
        }
      }

      &-subvalue {
        font-size: 16px;
        margin-top: 11px;
        margin-left: 0;
      }

      &-unit {
        font-size: 24px;
        margin-left: 10px;
        position: relative;
        bottom: 1px;
      }

      &:nth-child(1) {
        .stake-info__item-wrapper {
          box-shadow: 0px 12px 0 0px #1d7a5f;
        }
      }
      &:nth-child(2) {
        .stake-info__item-wrapper {
          box-shadow: 0px 12px 0 0px $primary;
        }
      }
      &:nth-child(3) {
        .stake-info__item-wrapper {
          box-shadow: 0px 12px 0 0px #a7b6bd;
        }
      }
    }
  }
}
