.gas-option {
  margin-top: 25px;

  &__title {
    font-size: 12px;
    font-weight: 500;
    color: $grey;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: calc(100% - 35px);
      position: absolute;
      top: 8px;
      border: 1px dashed $gray-light;
    }

    &-text {
      display: inline-block;
      padding-right: 20px;
      background: white;
      position: relative;
      z-index: 1;
    }
  }

  &__item {
    display: inline-block;
    margin: 15px 20px 0 0;
    text-align: left;
    min-width: 35%;
  }

  &__value {
    font-size: 13px;
    font-weight: 500;
    color: $grey;
    margin-right: 5px;
  }

  &__text {
    font-size: 11px;
    color: $black-lighter;
    text-transform: uppercase;
  }

  &__estimate {
    font-size: 11px;
    font-weight: 500;
    color: $grey;
  }

  &__info {
    color: white;
    padding: 1px 5px;
    font-size: 11px;
    border-radius: 50%;
    background-color: $black-lighter;
    position: relative;
    bottom: 2px;
  }

  &__note {
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    color: $black-lighter;
    margin-top: 18px;
  }

  .slide-down__arrow {
    right: 0;
    border-top-color: $primary;
  }

  .common__option-check {
    top: 3px;
  }

  .common__tooltip-content {
    top: -85px;
  }
}

.migration {
  .gas-option {
    .slide-down__fade-in {
      display: flex;
      justify-content: space-between;
      .common__option {
        min-width: auto;
      }
    }
  }
}
