.delegation {
  &__block {
    margin-bottom: 30px;
    padding: 40px 50px;
    background-color: white;
    border-radius: 5px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    color: $black;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 500;
    color: $primary;
    margin: 20px 0 25px;
  }

  &__text {
    font-size: 17px;
    color: #727272;
    margin-bottom: 35px;
    line-height: 1.5;
  }

  &__content-container {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    width: 65%;
  }

  &__image {
    width: 25%;
  }

  @media screen and (max-width: $breakpoint-lg) {
    &__block {
      padding: 20px 16px;
    }

    &__content-container {
      flex-direction: column-reverse;
    }

    &__content,
    &__image {
      width: 100%;
    }

    &__image {
      max-width: 300px;
      align-self: center;
      margin-top: 20px;
    }
  }
}
