/* ============ Fonts ============ */
$font-base: "Work Sans", "Roboto", sans-serif;

/* ============ Colors ============ */
$blue: #1752f0;
$blue-dark: #1143c8;
$blue-darker: #0b40cf;
$blue-light: #08a1e7;
$blue-lighter: #e3ebff;
$sky: #64b5f6;
$sky-light: #e9efff;
$green: #31cb9e;
$green-light: #b0ffe6;
$green-lighter: #eafaf5;
$green-dark: #0faaa2;
$green-darker: #1d7a5f;
$orange: #ffaf01;
$violet: #8041d8;
$purple: #5154eb;
$red: #fa6566;
$red-light: #ffeaea;
$black: #1e1e1e;
$black-light: #3a3a3a;
$black-lighter: #9ea1aa;
$black-lightest: #e1e4eb;
$grey: #5a5e67;
$grey-subtext: #868787;
$grey-light: #949394;
$grey-lighter: #f6f7fa;
$grey-lightest: #eef1f4;
$gray-bold: #6a6a6a;
$gray: #dcdbdc;
$gray-light: #e8e9ed;
$gray-lighter: #f5f5f5;
$gray-lightest: #eaecf0;
$gray-dark: #e2e2e2;
$white: #ffffff;

$primary: $green;
$primary-light: $green-light;
$primary-lighter: $green-lighter;
$primary-darker: #17b183;

/* ============ Breakpoints ============ */
$breakpoint-xl: 1200px;
$breakpoint-lg: 1130px;
$breakpoint-md: 768px;
$breakpoint-sm: 576px;
$breakpoint-xs: 0;

/* ============ Settings ============ */
$scrollBarWidth: 14px;
$image-path: "~src/assets/images/";
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1050px,
);
$grid-breakpoints: (
  xs: $breakpoint-xs,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl,
);
$grid-gutter-width: 20px;

$header-height: 80px;
$footer-height: 55px;
$footer-mobile-height: 168px;

$theme-colors: (
  "primary": $blue,
  "success": $green,
  "secondary": $black-lighter,
  "info": $sky,
  "warning": $orange,
  "danger": $red,
  "light": $grey,
  "dark": $black,
  "gray-bold": $gray-bold,
  "purple": $purple,
);
