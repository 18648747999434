.footer {
  bottom: 0;
  width: 100%;
  background-color: white;
  color: $grey-subtext;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.04);

  &__data {
    font-size: 14px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 15px 0 15px;
    background-color: $gray-light;
    color: $black-light;

    &-item {
      margin: 0 15px 10px 0;
      display: flex;
      align-items: center;

      &::after {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $primary;
        margin-left: 15px;
      }

      &:last-child {
        margin-right: 0;

        &::after {
          content: none;
        }
      }
    }
  }

  &__content {
    font-size: 12px;
    font-weight: 500;
    line-height: 32px;
    padding: 12px 0 10px;
    display: flex;
    justify-content: space-between;
  }

  &__menus {
    display: none;

    @media screen and (min-width: $breakpoint-md) {
      display: flex;
      flex: 1;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      & > div {
        display: flex;
        align-items: center;

        .social {
          a {
            padding: 0 8px;

            svg {
              display: inline;
              vertical-align: middle;
            }
          }
        }
        .links {
          a {
            padding: 0 16px;
          }
        }
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
  }

  &__menus__mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;

    @media screen and (min-width: $breakpoint-md) {
      display: none;
    }

    display: flex;
    flex-direction: column;

    .social {
      height: 50px;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        padding: 0 15px;
      }
    }
  }

  &--home-page {
    color: $white;
    background-color: transparent;

    .footer__menu {
      border-bottom: 0;
    }
  }

  .container {
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;
      align-items: baseline;
    }
  }
}
