.error-page {
  text-align: center;

  &__image {
    margin-top: 120px;
    width: 520px;
  }

  &__text {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
    color: $black;

    &-error {
      font-size: 18px;
      color: $red
    }
  }

  &__stack {
    text-align: left;
    width: 700px;
    margin: 20px auto;
  }
}