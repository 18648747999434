* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $font-base;
  color: $black-light;
  background-color: $white;
  min-width: 350px;
}

input {
  border: none;
  outline: none;
  font-family: $font-base;
}

button {
  font-family: $font-base;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ol {
  padding-left: 25px;
  margin: 5px 0;
}
