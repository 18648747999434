.migration {
  width: 800px;
  margin: 0 auto;
  max-width: 100%;
  padding: 40px;
  &__arrow {
    margin: 18px 0;
    transform: rotate(90deg);
    @media screen and (min-width: $breakpoint-lg) {
      transform: rotate(0);
    }
  }
}
