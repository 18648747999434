.icon {
  &__default {
    width: 20px;
    height: 10px;
    display: inline-block;
    background-image: url($image-path + "icons/link.svg");
  }

  &__github {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-image: url($image-path + "logos/github.svg");
  }

  &__medium {
    width: 20px;
    height: 20px;
    position: relative;
    top: 2px;
    display: inline-block;
    background-image: url($image-path + "logos/medium.svg");
  }

  &__etherscan {
    width: 15px;
    position: relative;
    top: 2px;
  }
}
