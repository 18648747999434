.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  z-index: 999;
  overflow: auto;
  transition: all 0.3s;

  &--active {
    visibility: visible;
    opacity: 1;
  }
}

.is-modal-open {
  overflow: hidden;
  margin-right: $scrollBarWidth;
}

.modal {
  max-width: 90%;
  max-height: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1000;
  pointer-events: none;

  &__close-btn {
    font-size: 25px;
    color: $gray-bold;
    position: absolute;
    top: 20px;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &__content {
    margin: 10px 0 50px;
    background-color: $white;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: scale(0.9);
    border-radius: 5px;
    box-shadow: 0 12px 64px 0 rgba(19, 23, 32, 0.81);
    transition: all 0.2s;
  }

  &--active {
    pointer-events: visible;

    .modal__content {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
  }

  &__block {
    padding: 20px 35px;

    &.grey {
      background-color: $grey-lighter;
    }
  }

  &__text {
    font-size: 14px;

    &.light {
      color: $grey;
    }

    &.bold {
      font-weight: 500;
      color: $black;
    }
  }

  &__image {
    width: 100%;
    max-width: 100px;
    margin: 20px auto 25px;
    display: block;
  }

  &--page {
    background-color: $white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    z-index: 10;

    &__header {
      background-color: $primary;
      height: 50px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;

      img {
        position: absolute;
        left: 10px;
        top: 16px;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    &__close-btn {
      right: 14px;
    }

    &-content {
      padding: 25px 15px !important;
    }
  }
}

.modal-interact {
  text-align: left;
  font-size: 15px;
  color: #3a3a3a;
  font-weight: normal;
  width: 430px;
  max-width: 100%;

  &.large {
    width: 750px;
  }

  &.medium {
    width: 450px;
  }

  &.small {
    width: 300px;
  }

  .modal-content {
    text-align: center;
    padding: 50px 35px 25px 35px;

    &__title {
      font-size: 28px;
      font-weight: 500;
      color: $black-light;
    }

    &__sub-title {
      margin-top: 4px;
      font-size: 18px;
      color: $black-light;
    }

    &__text {
      font-size: 14px;
      color: $black-light;
      line-height: 1.4;
      margin-bottom: 7px;
    }
  }

  .panel {
    text-align: center;
    padding: 25px 0;
    background-color: $grey-lighter;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    @media screen and (max-width: $breakpoint-lg) {
      background-color: $white;
    }
  }

  .panel-light {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .image {
    margin-bottom: 28px;
  }

  .ins {
    font-weight: 400;
  }

  .amount {
    font-size: 28px;
    font-weight: 500;
    margin-top: 12px;

    &__value {
      color: #141927;
      margin-right: 8px;
    }

    &__token {
      color: $green;
    }
  }

  .address {
    text-align: left;

    &__wrapper {
      display: flex;
      align-items: center;
      padding: 12px;
      background-color: $grey-lighter;
    }

    .addr {
      color: $primary;
      font-size: 14px;
      font-weight: 500;
      width: calc(100% - 25px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .copy {
    margin-top: -5px;
    cursor: pointer;
    content: "";
    width: 13px;
    height: 16px;
    display: inline-block;
    background-image: url($image-path + "icons/copy.svg");
  }

  .copy-status {
    margin-top: 3px;
    font-size: 12px;
    color: $primary;
    text-align: center;
    color: $grey;
  }

  .note {
    font-size: 14px;
    padding: 10px 0;
    color: $primary;
    font-weight: 500;

    span {
      display: block;
      padding-left: 42px;
      position: relative;
      min-height: 32px;
      line-height: 1.4;

      &::before {
        content: "";
        width: 32px;
        height: 32px;
        background-image: url($image-path + "icons/note.svg");
        position: absolute;
        left: 0;
      }
    }

    input {
      border-radius: 5px;
      background-color: $grey-lighter;
      font-size: 13px;
      padding: 11px 14px;
      width: 100%;
      max-width: 380px;
      margin-top: 15px;
    }
  }

  .common__button {
    min-width: 128px;

    &--hollow {
      margin-left: 0;
      margin-right: 6px;
    }
  }
}

.modal__tx-done {
  .ins {
    margin-top: 25px;
  }

  .address {
    margin-top: 25px;
  }

  .note {
    margin-top: 20px;
  }
}

.modal-broadcast {
  .modal-broadcast__transaction {
    padding: 25px 35px;

    .view-on img {
      margin-top: 0;
    }
  }

  .modal-broadcast__result {
    position: relative;

    .transaction-error {
      position: absolute;
      top: 23px;
    }
  }

  .title {
    justify-content: center;
    align-items: center;

    div {
      margin: 22px 0 0 5px !important;
      font-size: 28px;
      font-weight: 500;
    }
  }

  .broadcast-mining {
    color: #727b8f !important;
  }

  .broadcast-mined {
    font-weight: 500;
    color: $grey !important;
  }
}

#walletconnect-qrcode-modal {
  .walletconnect {
    &-modal {
      &__base {
        max-width: 400px;
      }

      &__headerLogo {
        max-width: 200px;
      }
    }

    &-qrcode {
      &__text {
        font-size: 16px;
      }

      &__image {
        height: 300px;
        padding: 15px 10px 30px;
      }
    }
  }
}

.loading-modal {
  padding: 30px;
  text-align: center;

  img {
    width: 30px;
  }

  &__info {
    border-top: 1px dashed $grey-light;
    margin-top: 20px;
    padding-top: 20px;
    line-height: 1.4;
    font-size: 15px;
  }
}
