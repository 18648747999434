.staking {
  width: 500px;
  max-width: 100%;
  padding: 15px;
  margin: 0 auto;
  @media screen and (min-width: $breakpoint-sm) {
    padding: 40px;
  }
  &__button-container {
    text-align: center;
    margin-top: 30px;
  }

  &__button {
    min-width: 128px;
    margin-bottom: 8px;
  }
}
